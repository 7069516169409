<template>
    <v-container>
        <h3 class="grey--text text--darken-2"> Documents </h3>

        <p class="subtitle-1 grey--text text--darken-2">
            Retrouvez ici les différents documents relatifs à votre acquisition du lot
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.product.reference }}</v-chip>
            sur le programme
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.program.title }}</v-chip>.
        </p>

        <p class="caption grey--text text--darken-2">
            Sélectionnez la catégorie de document que vous souhaitez consulter.
        </p>

        <template v-if="$vuetify.breakpoint.mobile">
            <v-expansion-panels >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span class="mr-1"> Dossier de vente </span> ({{ getDocumentsByType('sale').length }})
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <DocumentsView :documents="getDocumentsByType('sale')"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span class="mr-1"> Appels de fonds </span> ({{ getDocumentsByType('timetable').length }})
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <DocumentsView :documents="getDocumentsByType('timetable')"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span class="mr-1"> Pièces justificatives </span> ({{ getDocumentsByType('idk').length }})
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <DocumentsView :documents="getDocumentsByType('idk')"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span class="mr-1"> Contrats de travaux </span> ({{ getDocumentsByType('workcontract').length }})
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <DocumentsView :documents="getDocumentsByType('workcontract')"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span class="mr-1"> Kit livraison </span> ({{ getDocumentsByType('kitlivraison').length }})
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <DocumentsView :documents="getDocumentsByType('kitlivraison')"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span class="mr-1"> Autres </span> ({{ getDocumentsByType('other').length }})
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <DocumentsView :documents="getDocumentsByType('other')"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
        <template v-else>
            <v-tabs v-model="tab" :vertical="$vuetify.breakpoint.mobile">
                <v-tab @click="switchTab('sale')" v-if="getConfig('documents.allowed_documents_types', []).includes('sale')">
                    Dossier de vente
                </v-tab>

                <v-tab @click="switchTab('timetable')" v-if="getConfig('documents.allowed_documents_types', []).includes('timetable')">
                    Appels de fonds
                </v-tab>

                <v-tab @click="switchTab('idk')" v-if="getConfig('documents.allowed_documents_types', []).includes('idk')">
                    Pièces justificatives
                </v-tab>

                <v-tab @click="switchTab('workcontract')" v-if="getConfig('documents.allowed_documents_types', []).includes('workcontract')">
                    Contrats de travaux
                </v-tab>

                <v-tab @click="switchTab('kitlivraison')" v-if="getConfig('documents.allowed_documents_types', []).includes('kitlivraison')">
                    Kit livraison
                </v-tab>

                <v-tab @click="switchTab('other')" v-if="getConfig('documents.allowed_documents_types', []).includes('other')">
                    Autres
                </v-tab>
            </v-tabs>

            <v-card id="docviewer" class="pa-5 mt-5 blue-grey lighten-5" flat>
                <v-row>
                    <v-col cols="12 pb-0">
                        <v-btn @click="downloadDocuments()" small class="float-right" color="primary" >
                            TOUT TÉLÉCHARGER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="tabDocuments.length > 0">
                    <v-col cols="12" lg="3" v-for="document of tabDocuments" :key="document.id">
                        <v-card class="fill-height d-flex flex-column">
                            <v-card-text class="text-center flex-grow-1">
                                <v-icon color="red lighten-1"> {{ getDocumentIcon(document) }} </v-icon>

                                <br>

                                <template v-if="document.title">
                                    {{ document.title }}
                                </template>
                                <template v-else>
                                    <i> [Document sans nom] </i>
                                </template>
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                                <p class="caption mb-0">
                                    {{ document.date | toDate() }}
                                </p>

                                <v-spacer />

                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn :href="getDocumentUrl(document.id, true)" target="_blank" icon small color="primary" v-on="on">
                                            <v-icon> mdi-download </v-icon>
                                        </v-btn>
                                    </template>
                                    Télécharger le document
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn :href="getDocumentUrl(document.id)" target="_blank" icon small color="primary" v-on="on">
                                            <v-icon> mdi-open-in-app </v-icon>
                                        </v-btn>
                                    </template>
                                    Visualiser le document dans un nouvel onglet
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col>
                        <h2 class="text-center grey--text"> Aucun document </h2>
                    </v-col>
                </v-row>
            </v-card>
        </template>

        <!-- <v-item-group v-model="tab" mandatory>
            <div class="d-flex justify-space-between flex-wrap" @click="$vuetify.goTo('#docviewer', { duration: 300, easing: 'easeInOutCubic' })">
                <v-item v-slot="{ active, toggle }" value="sale" v-if="getConfig('documents.allowed_documents_types', []).includes('sale')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Dossier de vente
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-folder-key </v-icon>
                            <br>
                            {{ getDocumentsByType('sale').length }} {{ getDocumentsByType('sale').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="product" v-if="getConfig('documents.allowed_documents_types', []).includes('product')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Plans
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-floor-plan </v-icon>
                            <br>
                            {{ getDocumentsByType('product').length }} {{ getDocumentsByType('product').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="program" v-if="getConfig('documents.allowed_documents_types', []).includes('program')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Programme
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-file-document-multiple-outline </v-icon>
                            <br>
                            {{ getDocumentsByType('program').length }} {{ getDocumentsByType('program').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="timetable" v-if="getConfig('documents.allowed_documents_types', []).includes('timetable')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Appels de fonds
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-cash-multiple </v-icon>
                            <br>
                            {{ getDocumentsByType('timetable').length }} {{ getDocumentsByType('timetable').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="idk" v-if="getConfig('documents.allowed_documents_types', []).includes('idk')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Pièces justificatives
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-scale-balance </v-icon>
                            <br>
                            {{ getDocumentsByType('idk').length }} {{ getDocumentsByType('idk').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="workcontract" v-if="getConfig('documents.allowed_documents_types', []).includes('workcontract')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Contrats de travaux
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-tools </v-icon>
                            <br>
                            {{ getDocumentsByType('workcontract').length }} {{ getDocumentsByType('workcontract').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="kitlivraison" v-if="getConfig('documents.allowed_documents_types', []).includes('kitlivraison')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Kit livraison
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-key </v-icon>
                            <br>
                            {{ getDocumentsByType('kitlivraison').length }} {{ getDocumentsByType('kitlivraison').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="other" v-if="getConfig('documents.allowed_documents_types', []).includes('other')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Autres
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-expand-all-outline </v-icon>
                            <br>
                            {{ getDocumentsByType('other').length }} {{ getDocumentsByType('other').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>
            </div>
        </v-item-group> -->
    </v-container>
</template>

<script>
import documentsMixin from '../mixins/documents';
import DocumentsView from '../components/documents/DocumentsView.vue';

export default {
    name: 'Documents',

    mixins: [documentsMixin],

    components: {
        DocumentsView
    },

    data: () => ({
        tab: 0,
        tabKey: 'sale'
    }),

    computed: {
        tabDocuments() {
            return this.getDocumentsByType(this.tabKey);
        }
    },

    methods: {
        switchTab(tab) {
            this.tabKey = tab;
            this.$vuetify.goTo('#docviewer', { duration: 300, easing: 'easeInOutCubic' });
        },

        getDocumentsByType(type) {
            return this.selectedSale.documents.filter((d) => d.type === type);
        },
        async downloadDocuments() {
            try {
                this.setLoading(true);
                const allowedTypes = this.getConfig('documents.allowed_documents_types', []);
                const docsToDownload = [];
                allowedTypes.forEach(element => {
                    this.getDocumentsByType(element).forEach(doc => {
                        docsToDownload.push(doc);
                    });
                });
                const documentIds = docsToDownload.map(doc => doc.id);
                const documentObject = docsToDownload.reduce((acc, doc) => {
                    acc[doc.id] = doc.title || '[Document sans nom]';
                    return acc;
                }, {});
                const blob = await this.repos.documents.downloadDocuments({ ids: documentIds, docs: documentObject });
                const downloadResult = JSON.parse(decodeURIComponent(blob.headers['X-Download-Result']));                
                const url = window.URL.createObjectURL(blob.blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `Documents - Vente ${this.selectedSale.buyer.name} ref ${this.selectedSale.product.reference}_${this.selectedSale.id}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.setLoading(false);
                if (downloadResult.failed.length > 0) {
                    const failedFileNames = downloadResult.failed.map(doc => doc.fileName).join(', ');
                    this.$notify({
                        title: 'Erreur',
                        text: `Certains documents n'ont pas pu être téléchargés : ${failedFileNames}`,
                        type: 'error'
                    });
                    
                }
            } catch (error) {
                this.setLoading(false);
                console.error('Download failed:', error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.slide-transition {
    transition: transform 0.5s;
}

.card-active {
    transform: scale(1.1);
}
</style>
