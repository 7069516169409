<template>
    <div>
        <v-row v-if="documents.length > 0">
            <v-col cols="12" lg="3" v-for="document of documents" :key="document.id">
                <v-card class="fill-height d-flex flex-column">
                    <v-card-text class="text-center flex-grow-1">
                        <v-icon color="red lighten-1"> {{ getDocumentIcon(document) }} </v-icon>

                        <br>

                        <template v-if="document.title">
                            {{ document.title }}
                        </template>
                        <template v-else>
                            <i> [Document sans nom] </i>
                        </template>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                        <p class="caption mb-0">
                            {{ document.date | toDate() }}
                        </p>

                        <v-spacer />

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn :href="getDocumentUrl(document.id, true)" target="_blank" icon small color="primary" v-on="on">
                                    <v-icon> mdi-download </v-icon>
                                </v-btn>
                            </template>
                            Télécharger le document
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn :href="getDocumentUrl(document.id)" target="_blank" icon small color="primary" v-on="on">
                                    <v-icon> mdi-open-in-app </v-icon>
                                </v-btn>
                            </template>
                            Visualiser le document dans un nouvel onglet
                        </v-tooltip>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col>
                <h2 class="text-center grey--text"> Aucun document </h2>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import documentsMixin from '../../mixins/documents';

export default {
    name: 'DocumentsView',

    mixins: [documentsMixin],

    props: {
        documents: { type: Array, required: true }
    }
};
</script>